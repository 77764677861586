import React, { Component, useEffect, useState } from "react";
import Link from "next/link";
import { Carousel } from "react-responsive-carousel";

import styles from "../footer/footer.module.scss";
import DSLogo from "../dsLogo";
import { sendMetrics } from "../../utils/misc";
import { Modal } from "semantic-ui-react";
import { useStore } from "../../hooks/mobx";
import { useObserver } from "mobx-react";
import { useRouter } from "next/router";
import Spinner from "../spinner";
import CustomCityPicker from "../customCityPicker";
import { LinksWithMetric } from "../headerBurgerComponent/SideMenu";
import redirectTo from "../../utils/redirectTo";

const fpg = "/static/images/footer/fpg.png";
const obraz = "/static/images/footer/obraz.webp";
const mdg = "/static/images/footer/mdg.png";

export default function Footer({ opened }) {
  useEffect(() => {
    if (typeof document !== "undefined") {
      document.body.style.overflow = opened ? "hidden" : "auto";
    }
  }, [opened]);
  return (
    <footer className={styles.Footer}>
      <MainFooter />
      <LastFooter />
    </footer>
  );
}

const useStory = () => {
  const { authStore, uiStore, selectedCityStore, citiesStore } = useStore([
    "authStore",
    "authStore",
    "uiStore",
    "selectedCityStore",
    "citiesStore",
  ]);
  return useObserver(() => ({
    authStore,
    uiStore,
    selectedCityStore,
    citiesStore,
  }));
};

const CitySelector = () => {
  const { selectedCityStore, citiesStore } = useStory();
  const [city_name, set_city_name] = useState("");
  const [loading, setLoading] = useState(true);
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (selectedCityStore.cityData?.id) {
      set_city_name(selectedCityStore.cityData.title);
      setLoading(false);
    } else {
      if (selectedCityStore.cityID) {
        citiesStore
          .loadOne(selectedCityStore.cityID)
          .then((r) => {
            set_city_name(r.title);
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
            set_city_name("Ваш регион");
          });
      } else {
        set_city_name("Ваш регион");
        setLoading(false);
      }
    }
  }, [selectedCityStore.cityID, selectedCityStore.cityData]);

  if (loading) {
    return (
      <div className={"d-flex align-items-center"}>
        <Spinner toggled={true} customHeight={"32px"} customWidth={"32px"} />
      </div>
    );
  }

  if (opened) {
    return (
      <div className={styles.SelectorCity}>
        <CustomCityPicker />
        <img
          onClick={() => setOpened(false)}
          src="/static/images/icons/close.svg"
          alt="Close"
        />
      </div>
    );
  }

  if (!opened) {
    return (
      <div className={styles.SelectorText} onClick={() => setOpened(true)}>
        <img src="/static/images/MapPinMob.svg" alt="" />
        <span>{city_name}</span>
      </div>
    );
  }
};

export const MobileRightMenu: React.FC<{
  opened: boolean;
  setOpened: (v: boolean) => void;
}> = ({ opened, setOpened }) => {
  const router = useRouter();
  const { authStore } = useStory();
  const [hide, setHide] = useState(true);
  useEffect(() => {
    setOpened(false);
  }, [router]);
  useEffect(() => {
    let timer;
    if (!opened) {
      timer = setTimeout(() => {
        setHide(true);
      }, 400);
    } else {
      setHide(false);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [opened]);
  return (
    <div
      className={`${styles.MobileRightMenu} ${hide ? "d-none" : "d-flex"}`}
      data-opened={opened ? "true" : "false"}
    >
      <div
        className={styles.MobileRightMenu_Container}
        onClick={() => setOpened(false)}
      >
        <div className={styles.MobileRightMenu_Close}>
          <img
            onClick={() => setOpened(false)}
            src="/static/images/icons/close.svg"
            alt="Close"
          />
        </div>
        <DSLogo link={"/"} metric={"to_main_page_from_footer"} isMenuOpen={undefined} />
        {/* <div className={styles.MobileRightMenu_Container_Logo}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="150"
            height="40"
            viewBox="0 0 150 40"
            fill="none"
          >
            <path
              d="M14.3451 24.2228C14.3103 24.0832 14.2754 23.9436 14.2406 23.804C14.2406 23.7691 14.2058 23.7342 14.2058 23.6644C14.0665 22.8616 14.0317 22.1635 14.0665 21.5003C14.171 17.8354 16.6431 14.7289 19.9509 13.6469C17.1306 8.41131 12.9175 0.732422 12.9175 0.732422C12.9175 0.732422 5.01361 15.2874 3.69049 17.9401C2.36737 20.5928 1.00943 22.8616 1.00943 26.5614C1.00943 26.7708 0.974609 26.9802 0.974609 27.1897C0.974609 33.8214 6.33673 39.2316 12.9871 39.2316C15.9119 39.2316 18.6278 38.1844 20.6821 36.4043L20.125 35.6364C17.0261 31.2385 15.0763 27.3991 14.3451 24.2228Z"
              fill="#EF0000"
            />
            <path
              d="M28.517 21.3956C28.4474 18.1845 25.8708 15.6016 22.6326 15.6016C22.1103 15.6016 21.588 15.6714 21.1354 15.811C18.6632 16.4742 16.8178 18.708 16.783 21.3956C16.7482 21.7796 16.7482 22.3031 16.8875 22.9314C16.8875 22.9663 16.8875 22.9663 16.8875 23.0012C16.9223 23.1408 16.9223 23.2455 16.9571 23.3503C17.932 27.6784 21.4488 32.7743 22.5978 34.3799L22.6326 34.4148C23.7468 32.879 27.3332 27.7133 28.3081 23.3154C28.3429 23.2106 28.3777 23.071 28.3777 22.9663C28.3777 22.9314 28.3777 22.9314 28.3777 22.8965C28.5518 22.2682 28.5866 21.7796 28.517 21.3956ZM22.6674 23.8389C21.4139 23.8389 20.4042 22.8267 20.4042 21.5701C20.4042 20.3136 21.4139 19.3014 22.6674 19.3014C23.9209 19.3014 24.9306 20.3136 24.9306 21.5701C24.9306 22.8267 23.9209 23.8389 22.6674 23.8389Z"
              fill="#EF0000"
            />
            <path
              d="M36.7695 29.1427C36.7695 24.7099 36.7695 20.3469 36.7695 15.949C36.874 15.949 36.9436 15.9141 37.0133 15.9141C38.8238 15.9141 40.6344 15.9141 42.445 15.949C43.594 15.9839 44.7082 16.2631 45.718 16.8565C47.0759 17.6593 47.9464 18.846 48.3294 20.3818C48.8169 22.2666 48.7472 24.1165 47.8768 25.8966C47.1107 27.4673 45.8224 28.4097 44.186 28.7936C43.3851 29.0031 42.5495 29.0729 41.7138 29.1078C40.0773 29.1776 38.4408 29.1427 36.7695 29.1427ZM39.5899 26.6645C40.7041 26.6296 41.7835 26.6645 42.8628 26.5249C44.3252 26.3155 45.3002 25.3032 45.6135 23.8373C45.7876 22.9647 45.7876 22.0572 45.6135 21.1846C45.3002 19.7535 44.3601 18.7413 42.9325 18.5319C41.9575 18.3922 40.9478 18.4272 39.9729 18.3573C39.6595 18.3224 39.5899 18.4621 39.5899 18.7413C39.5899 21.2544 39.5899 23.7675 39.5899 26.2806C39.555 26.3853 39.5899 26.49 39.5899 26.6645Z"
              fill="#EF0000"
            />
            <path
              d="M100.105 19.3003C99.2001 18.7418 98.3296 18.3928 97.3895 18.2532C96.5191 18.1136 95.6486 18.0787 94.7781 18.323C94.2907 18.4626 93.9425 18.7418 93.8728 19.2305C93.8032 19.7191 94.0469 20.1031 94.4299 20.3125C95.1263 20.6615 95.8575 20.9408 96.5887 21.22C97.494 21.604 98.4689 21.9181 99.3046 22.4067C101.185 23.5237 101.672 25.9321 100.488 27.6773C99.8617 28.6197 98.8867 29.0385 97.8074 29.2479C95.5789 29.6319 93.4202 29.3876 91.3659 28.4102C91.1221 28.3055 91.0177 28.1659 91.0177 27.8867C91.0525 27.2584 91.0177 26.5952 91.0177 25.967C91.0177 25.8622 91.0177 25.7575 91.0525 25.6528C91.7489 25.967 92.4104 26.2811 93.1068 26.5254C94.3603 26.9443 95.6834 27.049 97.0065 26.8047C97.1458 26.7698 97.2503 26.7348 97.3895 26.6999C98.3296 26.3509 98.5037 25.3387 97.7029 24.7104C97.2503 24.3614 96.728 24.152 96.2057 23.9425C95.2308 23.5586 94.2558 23.2095 93.2809 22.7907C92.4104 22.4068 91.6792 21.8134 91.2614 20.9059C90.3909 18.9862 91.2614 16.8221 93.2113 16.0891C95.4745 15.2514 97.7029 15.5656 99.8617 16.5429C99.9313 16.5778 100.036 16.7174 100.036 16.7872C100.105 17.6249 100.105 18.4277 100.105 19.3003Z"
              fill="#EF0000"
            />
            <path
              d="M142.619 29.108C141.749 29.108 140.878 29.108 140.008 29.108C140.008 24.7101 140.008 20.3471 140.008 15.9492C140.843 15.9492 141.714 15.9492 142.584 15.9492C142.584 17.5548 142.584 19.1604 142.584 20.8009C143.072 20.5216 143.49 20.2424 143.942 20.0679C145.997 19.3 148.747 20.1726 148.921 23.0347C149.061 25.0243 148.956 27.0487 148.956 29.108C148.121 29.108 147.285 29.108 146.38 29.108C146.38 28.9684 146.345 28.8288 146.345 28.6892C146.345 27.1185 146.345 25.5129 146.345 23.9422C146.345 23.2442 146.206 22.6159 145.509 22.2668C144.534 21.7782 143.316 22.197 142.654 23.1394C142.584 23.2442 142.584 23.4187 142.584 23.5583C142.584 25.2686 142.584 26.9789 142.584 28.6543C142.619 28.8288 142.619 28.9684 142.619 29.108Z"
              fill="#EF0000"
            />
            <path
              d="M122.285 27.3625C122.285 27.8512 122.285 28.3399 122.285 28.8634C122.285 28.9332 122.215 29.0728 122.146 29.1077C121.24 29.387 120.335 29.4917 119.5 29.003C119.256 28.8634 119.047 28.6191 118.838 28.4446C118.281 28.6889 117.759 29.0379 117.167 29.2125C116.052 29.5615 114.903 29.5964 113.824 29.0379C112.884 28.5493 112.396 27.7116 112.396 26.6296C112.396 25.5126 112.884 24.7098 113.894 24.1863C115.008 23.5929 116.227 23.5929 117.445 23.6976C117.793 23.7325 118.142 23.8023 118.49 23.8372C118.664 23.0694 118.246 22.4062 117.445 22.1619C116.714 21.9524 115.983 21.9873 115.252 22.1269C114.59 22.2666 113.929 22.4411 113.232 22.6156C113.232 21.9175 113.232 21.2194 113.232 20.5214C113.232 20.4516 113.302 20.3817 113.371 20.3817C115.078 19.8582 116.784 19.579 118.525 20.0327C120.196 20.4865 121.101 21.6383 121.171 23.4184C121.206 24.3957 121.206 25.373 121.206 26.3503C121.136 27.3625 121.24 27.4673 122.285 27.3625ZM118.525 25.373C117.584 25.1985 116.679 25.024 115.774 25.4079C115.217 25.6523 115.008 26.0362 115.078 26.5947C115.112 27.0135 115.495 27.3975 116.018 27.4673C116.853 27.6069 117.619 27.4673 118.316 26.9437C118.42 26.8739 118.525 26.7343 118.525 26.5947C118.525 26.2107 118.525 25.8268 118.525 25.373Z"
              fill="#EF0000"
            />
            <path
              d="M77.1599 29.4222C75.3841 29.3524 73.8173 28.7939 72.8424 27.2232C71.3451 24.8149 72.0415 20.3821 76.4287 19.8585C77.6126 19.7189 78.7616 19.8585 79.841 20.4519C81.5471 21.3943 82.3827 23.1744 82.139 25.2686C81.8605 27.5374 80.1892 29.143 77.8215 29.3524C77.6126 29.3873 77.4036 29.3873 77.1599 29.4222ZM74.8619 24.6403C74.827 24.6403 75.036 25.7224 75.3145 26.1412C75.8716 27.0487 76.8117 27.1883 77.7866 27.0138C78.6571 26.8393 79.3187 26.1761 79.458 25.2686C79.6321 24.2215 79.5276 23.2442 78.5527 22.581C77.7518 22.0225 76.8814 22.0225 76.0109 22.4414C75.1404 22.8602 74.8619 23.6979 74.8619 24.6403Z"
              fill="#EF0000"
            />
            <path
              d="M54.9104 29.4234C53.0999 29.3536 51.6375 28.8649 50.6625 27.4339C49.5831 25.8632 49.5135 24.1529 50.2795 22.4775C51.0107 20.837 52.3687 20.0691 54.1096 19.8597C55.7809 19.6503 57.3478 19.9993 58.5316 21.221C59.8896 22.6171 60.1333 24.3623 59.5762 26.1773C59.0539 27.9574 57.7308 28.9348 55.9202 29.2838C55.5372 29.3536 55.1542 29.3885 54.9104 29.4234ZM54.806 22.1634C53.4132 22.1634 52.5079 23.1407 52.5079 24.6067C52.5079 26.1075 53.4132 27.0499 54.806 27.0499C56.2336 27.0499 57.1737 26.0726 57.1737 24.5718C57.1737 23.1407 56.2336 22.1285 54.806 22.1634Z"
              fill="#EF0000"
            />
            <path
              d="M111.248 25.4789C109.089 25.4789 106.965 25.4789 104.841 25.4789C105.05 26.3515 105.607 26.8053 106.373 27.0845C107.696 27.5383 109.019 27.3986 110.342 26.9798C110.447 26.9449 110.551 26.91 110.69 26.8751C110.725 26.8751 110.725 26.8751 110.795 26.8751C110.795 27.4685 110.795 28.0618 110.795 28.6552C110.795 28.9693 110.516 28.9693 110.307 29.0391C109.089 29.4231 107.835 29.4929 106.582 29.3882C105.677 29.2835 104.841 29.0042 104.11 28.5156C102.856 27.643 102.264 26.3864 102.195 24.8856C102.125 23.0356 102.752 21.465 104.423 20.5226C106.025 19.615 107.731 19.5801 109.367 20.4876C110.795 21.2555 111.317 22.5819 111.352 24.1177C111.352 24.5016 111.282 24.9554 111.248 25.4789ZM104.841 23.7337C106.199 23.7337 107.522 23.7337 108.845 23.7337C108.984 22.7215 108.288 21.9187 107.243 21.8489C105.851 21.7442 104.876 22.5121 104.841 23.7337Z"
              fill="#EF0000"
            />
            <path
              d="M64.172 29.1095C63.2667 29.1095 62.431 29.1095 61.5605 29.1095C61.5605 26.1077 61.5605 23.106 61.5605 20.1043C62.3266 20.1043 63.0578 20.1043 63.8238 20.1043C63.8238 20.4533 63.8238 20.7674 63.8238 21.1514C64.2764 20.8372 64.6246 20.5231 65.0424 20.2788C66.8182 19.2666 69.2555 19.8948 70.126 21.6749C70.4046 22.2334 70.509 22.8617 70.5438 23.4899C70.6135 25.305 70.5786 27.12 70.5786 28.935C70.5786 28.9699 70.5787 29.0397 70.5438 29.1095C69.6734 29.1095 68.8377 29.1095 67.9324 29.1095C67.9324 28.9699 67.9324 28.7954 67.9324 28.6557C67.9324 27.0502 67.9324 25.4097 67.9324 23.8041C67.9324 23.106 67.6887 22.5126 66.9923 22.2334C66.087 21.8495 64.7291 22.3032 64.2416 23.1409C64.172 23.2456 64.172 23.4201 64.172 23.5598C64.172 25.2701 64.172 26.9454 64.172 28.6557C64.172 28.7954 64.172 28.935 64.172 29.1095Z"
              fill="#EF0000"
            />
            <path
              d="M138.162 22.6159C137.466 22.4414 136.839 22.232 136.178 22.1273C135.725 22.0575 135.272 22.0924 134.82 22.1971C133.531 22.4763 132.87 23.4536 132.974 24.8498C133.044 26.0714 133.914 26.944 135.133 27.0836C136.108 27.1883 137.048 27.0487 137.919 26.6648C137.988 26.6299 138.058 26.595 138.127 26.5601C138.162 26.5601 138.162 26.5601 138.232 26.5601C138.232 27.2582 138.232 27.9213 138.232 28.6194C138.232 28.6892 138.127 28.8288 138.058 28.8288C136.386 29.5269 133.601 29.7363 131.79 28.2704C130.641 27.328 130.189 26.0365 130.224 24.5706C130.258 22.7555 130.955 21.2896 132.591 20.417C134.332 19.5095 136.108 19.5095 137.919 20.3123C138.023 20.3472 138.127 20.5217 138.127 20.6264C138.162 21.3245 138.162 21.9877 138.162 22.6159Z"
              fill="#EF0000"
            />
            <path
              d="M126.185 29.1078C125.315 29.1078 124.479 29.1078 123.608 29.1078C123.608 26.106 123.608 23.1392 123.608 20.1025C124.374 20.1025 125.106 20.1025 125.906 20.1025C125.906 20.4865 125.906 20.8704 125.906 21.3591C126.881 20.1375 128.1 19.7535 129.528 19.8582C129.528 20.6959 129.528 21.4987 129.528 22.3015C128.936 22.3713 128.344 22.4062 127.787 22.5458C127.438 22.6505 127.09 22.86 126.777 23.1043C126.359 23.3835 126.185 23.8024 126.185 24.3608C126.22 25.7919 126.185 27.223 126.185 28.6889C126.185 28.7936 126.185 28.9682 126.185 29.1078Z"
              fill="#EF0000"
            />
            <path
              d="M83.9141 20.1028C84.6801 20.1028 85.4113 20.1028 86.2121 20.1028C86.2121 20.5216 86.2121 20.9056 86.2121 21.3942C87.187 20.1726 88.4057 19.7887 89.8333 19.8934C89.8333 20.7311 89.8333 21.5339 89.8333 22.3716C89.5547 22.3716 89.2762 22.3716 88.9976 22.3716C88.0923 22.4414 87.2915 22.7206 86.7344 23.4536C86.5951 23.6281 86.4907 23.9073 86.4907 24.1168C86.4558 25.6176 86.4907 27.1185 86.4907 28.6194C86.4907 28.759 86.4907 28.8986 86.4907 29.0731C85.6202 29.0731 84.7845 29.0731 83.9141 29.0731C83.9141 26.1412 83.9141 23.1394 83.9141 20.1028Z"
              fill="#EF0000"
            />
          </svg>
        </div> */}
        <div
          className={styles.MobileRightMenu_Container_Selector}
          onClick={(e) => e.stopPropagation()}
        >
          <CitySelector />
        </div>
        <div className={styles.MobileRightMenu_Container_Links}>
          <LinksWithMetric />
        </div>
        {authStore.user && (
          <div
            className={styles.MobileRightMenu_Container_User}
            onClick={() => {
              authStore.logOut();
            }}
          >
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M16.3135 8.0625L20.2499 12L16.3135 15.9375"
                  stroke="#4E5355"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.75 12H20.2472"
                  stroke="#4E5355"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.75 20.25H4.5C4.30109 20.25 4.11032 20.171 3.96967 20.0303C3.82902 19.8897 3.75 19.6989 3.75 19.5V4.5C3.75 4.30109 3.82902 4.11032 3.96967 3.96967C4.11032 3.82902 4.30109 3.75 4.5 3.75H9.75"
                  stroke="#4E5355"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <span>Выйти из аккаунта</span>
          </div>
        )}
      </div>
    </div>
  );
};

export const BottomMenu = (props) => {
  const { opened, setOpened } = props;
  const router = useRouter();

  const isActive = (url, exact = false) => {
    if (opened) return "false";
    if (exact) {
      if (router.pathname === url) {
        return "true";
      } else return "false";
    } else {
      if (router.pathname.includes(url)) {
        return "true";
      } else return "false";
    }
  };

  const { authStore } = useStory();
  return (
    <>
      <div className={styles.MobileFooter_Overflow} />
      <div className={`${styles.MobileFooter}`}>
        <div
          className={styles.MobileFooter_Item}
          data-active={isActive("/", true)}
          onClick={() => redirectTo("/")}
        >
          <div>
            <svg
              className=""
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.2495 19.5001V15C14.2495 14.8011 14.1704 14.6104 14.0298 14.4697C13.8891 14.3291 13.6984 14.25 13.4995 14.25H10.4995C10.3005 14.25 10.1098 14.3291 9.96912 14.4697C9.82847 14.6104 9.74945 14.8011 9.74945 15V19.5001C9.74945 19.699 9.67045 19.8898 9.52981 20.0304C9.38918 20.1711 9.19844 20.2501 8.99954 20.2501L4.50009 20.2507C4.40159 20.2507 4.30406 20.2313 4.21305 20.1937C4.12205 20.156 4.03936 20.1007 3.9697 20.0311C3.90005 19.9614 3.8448 19.8788 3.8071 19.7878C3.7694 19.6968 3.75 19.5992 3.75 19.5007V10.8326C3.75 10.7281 3.77183 10.6247 3.8141 10.5292C3.85637 10.4336 3.91814 10.3479 3.99545 10.2776L11.4949 3.45876C11.633 3.33324 11.8129 3.26368 11.9995 3.26367C12.186 3.26367 12.3659 3.33321 12.504 3.45873L20.0045 10.2776C20.0818 10.3479 20.1436 10.4336 20.1859 10.5292C20.2282 10.6247 20.25 10.7281 20.25 10.8326V19.5007C20.25 19.5992 20.2306 19.6968 20.1929 19.7878C20.1552 19.8788 20.1 19.9614 20.0303 20.0311C19.9606 20.1007 19.878 20.156 19.7869 20.1937C19.6959 20.2313 19.5984 20.2507 19.4999 20.2507L14.9994 20.2501C14.8005 20.2501 14.6097 20.1711 14.4691 20.0304C14.3285 19.8898 14.2494 19.699 14.2495 19.5001V19.5001Z"
                stroke="#8A8F93"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div>Главная</div>
        </div>
        <div
          onClick={() => redirectTo("/bloodstations/")}
          className={styles.MobileFooter_Item}
          data-active={isActive("/bloodstation")}
        >
          <div>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 12.75C13.6569 12.75 15 11.4069 15 9.75C15 8.09315 13.6569 6.75 12 6.75C10.3431 6.75 9 8.09315 9 9.75C9 11.4069 10.3431 12.75 12 12.75Z"
                stroke="#8A8F93"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M19.5 9.75C19.5 16.5 12 21.75 12 21.75C12 21.75 4.5 16.5 4.5 9.75C4.5 7.76088 5.29018 5.85322 6.6967 4.4467C8.10322 3.04018 10.0109 2.25 12 2.25C13.9891 2.25 15.8968 3.04018 17.3033 4.4467C18.7098 5.85322 19.5 7.76088 19.5 9.75V9.75Z"
                stroke="#8A8F93"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div>Где сдать?</div>
        </div>
        {!authStore.user ? (
          <div
            className={styles.MobileFooter_Item}
            data-active={isActive("/login")}
            onClick={() => {
              redirectTo(`/login/?next=${encodeURIComponent(router?.asPath)}`);
            }}
          >
            <div>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 3H19C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H15"
                  stroke="#8A8F93"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10 17L15 12L10 7"
                  stroke="#8A8F93"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M15 12H3"
                  stroke="#8A8F93"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div>Войти</div>
          </div>
        ) : (
          <div
            className={styles.MobileFooter_Item}
            data-active={isActive("/profile")}
            onClick={() => redirectTo("/profile/")}
          >
            <div>
              <svg
                width="24"
                height="24"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19Z"
                  stroke="#8A8F93"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                />
                <path
                  d="M10 13C12.0711 13 13.75 11.3211 13.75 9.25C13.75 7.17893 12.0711 5.5 10 5.5C7.92893 5.5 6.25 7.17893 6.25 9.25C6.25 11.3211 7.92893 13 10 13Z"
                  stroke="#8A8F93"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                />
                <path
                  d="M3.98096 16.6913C4.5459 15.5806 5.40719 14.6478 6.46948 13.9963C7.53177 13.3448 8.75363 13 9.99978 13C11.2459 13 12.4678 13.3448 13.5301 13.9963C14.5924 14.6478 15.4537 15.5806 16.0186 16.6913"
                  stroke="#8A8F93"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div>Профиль</div>
          </div>
        )}
        <div
          className={styles.MobileFooter_Item}
          data-active={opened}
          onClick={() => setOpened(!opened)}
        >
          <div>
            <svg
              className=""
              width="24"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 14.25C13.2426 14.25 14.25 13.2426 14.25 12C14.25 10.7574 13.2426 9.75 12 9.75C10.7574 9.75 9.75 10.7574 9.75 12C9.75 13.2426 10.7574 14.25 12 14.25Z"
                stroke="#8A8F93"
                strokeWidth="2"
                strokeMiterlimit="10"
              />
              <path
                d="M4.5 14.25C5.74264 14.25 6.75 13.2426 6.75 12C6.75 10.7574 5.74264 9.75 4.5 9.75C3.25736 9.75 2.25 10.7574 2.25 12C2.25 13.2426 3.25736 14.25 4.5 14.25Z"
                stroke="#8A8F93"
                strokeWidth="2"
                strokeMiterlimit="10"
              />
              <path
                d="M19.5 14.25C20.7426 14.25 21.75 13.2426 21.75 12C21.75 10.7574 20.7426 9.75 19.5 9.75C18.2574 9.75 17.25 10.7574 17.25 12C17.25 13.2426 18.2574 14.25 19.5 14.25Z"
                stroke="#8A8F93"
                strokeWidth="2"
                strokeMiterlimit="10"
              />
            </svg>
          </div>
          <div>Ещё</div>
        </div>
      </div>
    </>
  );
};

class MainFooter extends Component<{}, any> {
  state: {
    isAndroid: boolean;
    isModalOpen: boolean;
  };

  constructor(props) {
    super(props);
    this.state = {
      isAndroid: true,
      isModalOpen: false,
    };
  }

  componentDidMount(): void {
    if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
      this.setState({
        isAndroid: false,
      });
    } else {
      this.setState({
        isAndroid: true,
      });
    }
    // Скрипт при добавлении в head срабатывал не корректно и иногда не маунтил виджет,
    // поэтому явно добавляем скрипт в DOM-дерево
    const script = document.createElement('script');
    script.src = 'https://image.sendsay.ru/app/js/forms/forms.min.js';
    document.body.appendChild(script);
  }

  openModal = () => {
    this.setState((state) => {
      return { isModalOpen: !state.isModalOpen };
    });
  };

  render() {
    const { isAndroid, isModalOpen } = this.state;
    return (
      <div className={styles.MainFooter}>
        <div className={"container"}>
          <div className={styles.MainFooterContent}>
            <div className={styles.MainFooterContentTop}>
              <div className={styles.MainFooterContentTopLeft}>
                <DSLogo link={"/"} metric={"to_main_page_from_footer"} isMenuOpen={undefined} />
                <div className={styles.MainFooterContentTopLeftIcons}>
                  <a
                    className={styles.MainFooterContentTopLeftIconsVK}
                    href={"https://vk.com/donorsearch"}
                    target={"_blank"}
                  >
                    <div
                      onClick={() => {
                        sendMetrics("to_instagram_from_footer");
                      }}
                    >
                      {/*<i className="zmdi zmdi-vk"/>*/}
                      <img src="/static/images/icons/newVK.svg" alt="" />
                    </div>
                  </a>
                  {/*<a*/}
                  {/*  className={styles.MainFooterContentTopLeftIconsFB}*/}
                  {/*  href={"https://www.facebook.com/DonorSearch/"}*/}
                  {/*  target={"_blank"}*/}
                  {/*>*/}
                  {/*  <div>*/}
                  {/*    /!*<i className="zmdi zmdi-facebook"/>*!/*/}
                  {/*    <img src="/static/images/icons/newFB.svg" alt="" />*/}
                  {/*  </div>*/}
                  {/*</a>*/}
                  {/* <a
                    className={styles.MainFooterContentTopLeftIconsTwitter}
                    href={"https://twitter.com/Donor_Search"}
                    target={"_blank"}
                  >
                    <div>
                      <i className="zmdi zmdi-twitter"/>
                      <img src="/static/images/icons/newTwitter.svg" alt="" />
                    </div>
                  </a> */}
                  {/*<a*/}
                  {/*  className={styles.MainFooterContentTopLeftIconsInst}*/}
                  {/*  href={"https://www.instagram.com/donorsearch/"}*/}
                  {/*  target={"_blank"}*/}
                  {/*>*/}
                  {/*  <div*/}
                  {/*    onClick={() => {*/}
                  {/*      sendMetrics("to_instagram_from_footer");*/}
                  {/*    }}*/}
                  {/*  >*/}
                  {/*    /!*<i className="zmdi zmdi-instagram"/>*!/*/}
                  {/*    <img src="/static/images/icons/newInst.svg" alt="" />*/}
                  {/*  </div>*/}
                  {/*</a>*/}
                  <a
                    className={styles.MainFooterContentTopLeftIconsOK}
                    href={"https://ok.ru/donorsearch"}
                    target={"_blank"}
                  >
                    <div>
                      {/*<i className="zmdi zmdi-odnoklassniki"/>*/}
                      <img src="/static/images/icons/newOK.svg" alt="" />
                    </div>
                  </a>
                  <a
                    className={styles.MainFooterContentTopLeftIconsTG}
                    href={"https://t.me/donorsearch"}
                    target={"_blank"}
                  >
                    <div>
                      {/*<i><TelegramIcon/></i>*/}
                      <img src="/static/images/icons/newTG.svg" alt="" />
                    </div>
                  </a>
                  <a
                    className={styles.MainFooterContentTopLeftIconsTenChat}
                    href={"https://tenchat.ru/DonorSearch"}
                    target={"_blank"}
                  >
                    <div>
                      <img src="/static/images/icons/tenchat.svg" alt="" />
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div data-sendsay-form-embedded="donorsearch/10" className={styles.sendsayForm}></div>
            {!isAndroid && (
              <div className={styles.MainFooterBtn} onClick={this.openModal}>
                Установить мобильное приложение
              </div>
            )}
            <Modal
              className={styles.ProfileThankYouPageMainModal}
              onClose={this.openModal}
              onOpen={this.openModal}
              open={isModalOpen}
              style={{
                padding: "26px 6px 10px",
                bottom: "-2px",
                width: "100%",
                marginBottom: "0em",
                top: "unset",
                height: "auto",
              }}
            >
              <Modal.Content
                className={styles.MainFooterModal}
                style={{
                  padding: "40px 20px 24px",
                }}
              >
                <span className={styles.MainFooterModalTitle}>
                  Установка приложения
                </span>
                <span className={styles.MainFooterModalText}>
                  1. Нажмите на{" "}
                </span>
                <span className={styles.MainFooterModalIcon} />
                <span className={styles.MainFooterModalText}>
                  2. Выберите <br /> ”На экран Домой”
                </span>
                <button
                  onClick={this.openModal}
                  className={styles.MainFooterModalBtn}
                >
                  Хорошо
                </button>
              </Modal.Content>
            </Modal>
            {isAndroid && (
              <a
                target="_blank"
                href={
                  "https://play.google.com/store/apps/details?id=org.donorsearch"
                }
                className={styles.MainFooterBtnAndroid}
              />
            )}
            <div className={styles.MainFooterContentBot}>
              <div className={styles.MainFooterContentBotDiv}>
                <span>О донорстве</span>
                <div className={styles.MainFooterContentBotDivNormal}>
                  <span className={styles.MainFooterContentBotNormalLink}>
                    <Link href={"/blooddonation"}>
                      <a>Донорство крови</a>
                    </Link>
                  </span>
                  <span className={styles.MainFooterContentBotNormalLink}>
                    <Link href={"/how"}>
                      <a>Как стать донором?</a>
                    </Link>
                  </span>
                  <span className={styles.MainFooterContentBotNormalLink}>
                    <Link href={"/bloodstations"}>
                      <a>Где сдать кровь?</a>
                    </Link>
                  </span>
                  <span className={styles.MainFooterContentBotNormalLink}>
                    <Link href={"/requirements"}>
                      <a>Требования к донору</a>
                    </Link>
                  </span>
                  <span className={styles.MainFooterContentBotNormalLink}>
                    <Link href={"https://km.donorsearch.org/"}>
                      <a>Костный мозг</a>
                    </Link>
                  </span>
                </div>
              </div>
              <div className={styles.MainFooterContentBotDiv}>
                <span>О DonorSearch</span>
                <div className={styles.MainFooterContentBotDivNormal}>
                  <span className={styles.MainFooterContentBotNormalLink}>
                    <Link href={"/about"}>
                      <a>О проекте</a>
                    </Link>
                  </span>
                  <span className={styles.MainFooterContentBotNormalLink}>
                    <Link href={"/smi"}>
                      <a>СМИ о нас</a>
                    </Link>
                  </span>
                  <span className={styles.MainFooterContentBotNormalLink}>
                    <Link href={"/charityshop"}>
                      Наш магазин
                    </Link>
                  </span>
                  <span className={styles.MainFooterContentBotNormalLink}>
                    <a
                      href={"https://journal.donorsearch.org/"}
                      target={"_blank"}
                    >
                      Журнал
                    </a>
                  </span>
                  <span className={styles.MainFooterContentBotNormalLink}>
                    <a href="https://specials.donorsearch.org/">Спецпроекты</a>
                  </span>
                </div>
              </div>
              <div className={styles.MainFooterContentBotDiv}>
                <span>НКО</span>
                <div className={styles.MainFooterContentBotDivNormal}>
                  <span className={styles.MainFooterContentBotNormalLink}>
                    <Link href={"/non-profit"}>
                      <a>Об организации</a>
                    </Link>
                  </span>
                  <span className={styles.MainFooterContentBotNormalLink}>
                    <Link
                      href={"/donate/?utm_source=footer&utm_medium=ds&utm_campaign=donor_search"}
                    >
                      Помочь проекту
                    </Link>
                  </span>
                  <span className={styles.MainFooterContentBotNormalLink}>
                    <Link
                      href={"/fundraising"}
                    >
                      Сборы на проект
                    </Link>
                  </span>
                  <span className={styles.MainFooterContentBotNormalLink}>
                    <Link href={"/helpme/"}>
                      <a>Найти донора</a>
                    </Link>
                  </span>
                  <span className={styles.MainFooterContentBotNormalLink}>
                    <Link href={"/awards"}>
                      <a>Награды</a>
                    </Link>
                  </span>
                </div>
              </div>
              <div className={styles.MainFooterContentBotDiv}>
                <span>Наши партнеры</span>
                <div className={styles.MainFooterContentBotDivNormal}>
                  <span className={styles.MainFooterContentBotNormalLink}>
                    <Link href={"https://business.donorsearch.org/gratitude"}>
                      <a target="_blank">Благодарности</a>
                    </Link>
                  </span>
                  <span className={styles.MainFooterContentBotNormalLink}>
                    <Link href={"https://business.donorsearch.org/partners"}>
                      <a target="_blank">Партнеры</a>
                    </Link>
                  </span>
                  <div className={styles.MainFooterContentBotDivNormalCarousel}>
                    <Carusel />
                  </div>
                </div>
              </div>
              <div className={styles.MainFooterContentBotDiv}>
                <span>Организациям</span>
                <div className={styles.MainFooterContentBotDivNormal}>
                  <span className={styles.MainFooterContentBotNormalLink}>
                    <Link href={"https://business.donorsearch.org/"}>
                      <a target="_blank">Партнерство с бизнесом</a>
                    </Link>
                  </span>
                  <span className={styles.MainFooterContentBotNormalLink}>
                    <Link
                      href={"https://business.donorsearch.org/business-hr/"}
                    >
                      <a target="_blank">HR и донорство</a>
                    </Link>
                  </span>
                  <span className={styles.MainFooterContentBotNormalLink}>
                    <Link
                      href={
                        "https://business.donorsearch.org/business-marketing/"
                      }
                    >
                      <a target="_blank">Бизнес и донорство</a>
                    </Link>
                  </span>
                  <span className={styles.MainFooterContentBotNormalLink}>
                    <Link
                      href={"https://business.donorsearch.org/bonus-program/"}
                    >
                      <a target="_blank">Бонусная программа</a>
                    </Link>
                  </span>
                  <span className={styles.MainFooterContentBotNormalLink}>
                    <a
                      href={"https://unity.donorsearch.org/"}
                      target={"_blank"}
                    >
                      Мероприятия
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class Carusel extends Component {
  state: {
    selectedItem: number;
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedItem: 0,
    };
  }

  changeSelectedItem = (id) => {
    this.setState({
      selectedItem: id,
    });
  };

  render() {
    const { selectedItem } = this.state;
    return (
      <Carousel
        autoPlay
        className={styles.CarouselBlock}
        showArrows={false}
        showStatus={false}
        showIndicators={true}
        infiniteLoop={true}
        showThumbs={false}
        useKeyboardArrows={false}
        stopOnHover={false}
        swipeable={false}
        width={"80%"}
        dynamicHeight={false}
        emulateTouch={false}
        selectedItem={selectedItem}
        renderIndicator={(
          clickHandler: (e: React.MouseEvent | React.KeyboardEvent) => void,
          isSelected: boolean,
          index: number
        ) =>
          isSelected ? (
            <span className={styles.CustomDotsActive} />
          ) : (
            <span
              onClick={() => this.changeSelectedItem(index)}
              className={styles.CustomDots}
            />
          )
        }
      >
        <div>
          <img alt="" src={fpg} />
        </div>
        <div>
          <img alt="" src={obraz} />
        </div>
      </Carousel>
    );
  }
}

const LastFooter = () => {
  return (
    <div className={styles.LastFooter}>
      <div className={`container ${styles.Flex}`}>
        <div className={styles.LastFooterText}>
          <span className={styles.LastFooterTextCop}>
            DonorSearch © 2010-{new Date().getFullYear()}.
          </span>
          <span className={styles.LastFooterTextRights}>
            Все права защищены. Если не указано иное, все материалы сайта
            доступны по лицензии Creative Commons CC-BY-SA 4.0.
          </span>
          <span className={styles.LastFooterTextLinks}>
            Прочтите нашу{" "}
            <Link href={"/confidential"}>
              <a> политику конфиденциальности </a>
            </Link>{" "}
            и{" "}
            <Link href={"/rules"}>
              <a> пользовательское соглашение</a>
            </Link>
            .
          </span>
        </div>
        <div className={styles.Pronin}>
          <span>поддержка и разработка</span>
          <img src="/static/images/footer/pronin-team.svg" alt="" />
        </div>
      </div>
    </div>
  );
};
